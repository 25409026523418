import React from 'react'
import "./videoslider.css"
import Carousel from 'react-bootstrap/Carousel';
import ReactPlayer from 'react-player';

const Videoslider = () => {
    const videoProperties = [
        {
          id: 1,
          title: "Video 1",
          src: "https://youtu.be/___mkLFZUHg",
          credit: "Video by cottonbro from Pexels",
        },
        {
          id: 2,
          title: "Video 2",
          src: "https://youtu.be/___mkLFZUHg",
          credit: "Video by cottonbro from Pexels",
        },
        {
          id: 3,
          title: "Video 3",
          src: "https://youtu.be/___mkLFZUHg",
          credit: "Video by cottonbro from Pexels",
        },
      ];
    
      return (
        <div id='videogallery'>
        <div  >
            <div className='d-flex justify-content-center'>
            <p style={{fontSize:'34px',borderBottom:'3px solid black',paddingTop:'95px'}}>VIDEO GALLERY</p>
        </div >
          <Carousel className='d-flex justify-content-center'>
            {videoProperties.map((videoObj) => {
              return (
                <Carousel.Item key={videoObj.id} >
                  <ReactPlayer className='d-flex justify-content-center'
                    url={videoObj.src}
                    pip={true}
                    controls={true}
                   
                  />
                 
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        </div>
      );
    };

export default Videoslider
import React from 'react'
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./navbar.css"
import Container from "react-bootstrap/Container";

const Header = () => {
  return (
    <div>
        <div
        className="d-block align-items-center "
        style={{ position: "fixed", zIndex: "99999", width: "100%" }}
      >
        
        <Navbar bg="white" expand="lg">
          
          <Container fluid>
            <Navbar.Brand href="#home">
            
               <img
                  alt="..."
                  src={require("../assets/img/copy-logo.png")}
                />
            </Navbar.Brand>
           
        
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto justify-content-around ">
                <Nav.Link href="#aboutus">ABOUT US</Nav.Link>
                <Nav.Link href="#services">SERVICES</Nav.Link>
                <Nav.Link href="#start-up">START-UP SERVICES</Nav.Link>
                <Nav.Link href="#team">TEAM</Nav.Link>
                <Nav.Link href="#media">ACHIEVEMENT</Nav.Link>
                <Nav.Link href="#media">MEDIA COVERAGE</Nav.Link>
                <Nav.Link href="#gallery">GALLERY</Nav.Link>

                <Nav.Link href="#videogallery">VIDEO GALLERY</Nav.Link>
                <Nav.Link href="#contact-us">CONTACT US</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
          
         
        </Navbar>
        
      </div>
    </div>
  )
}

export default Header


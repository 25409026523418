import React from 'react'

const About = () => {
  return (
    <div id='aboutus'>
        <div className='d-flex justify-content-center '>
            <p style={{fontSize:'34px',borderBottom:'3px solid black',paddingTop:'95px'}}>ABOUT US</p>
        </div>
        <div className='px-5 pb-2'>
            <span>AT SAN WE ARE MORE THAN AN ADVISOR TO OUR CLIENTS, WE ARE PARTNERS.</span>
        </div>
        <div className='d-flex flex-row flex-wrap justify-content-center'>
            <div className='d-block p-4' style={{width:'600px'}}>
                <h3>Company Profile</h3>
                <div>SAN corporate advisors Pvt Ltd is a team of professionals led by the Founder-promoter Mr. Sanjay Landge.</div>
                <div>The company aims to provide comprehensive advisory services to businesses. A portfolio consisting of  of Management Accounting, Financial Structuring services, arrangement of funds through debt and private placement, support to start-ups, SME IPO.</div>
            </div>
            <div className='d-block p-4' style={{width:'600px'}}>
                <h3>Why SAN?</h3>
                <div>Over a decade of comprehensive experience in Project Establishments, Amalgamations, Strategic Alliance, Technological tie-ups. We have an association with experts and pioneers of economy consisting of Public Sector Banks, Private Sector Banks, Non Banking Finance Companies, Financial Institutions, Co-operative Banks, Venture Capitalists and private equity firms.</div>
                {/* <div>The company aims to provide comprehensive advisory services to businesses. A portfolio consisting of  of Management Accounting, Financial Structuring services, arrangement of funds through debt and private placement, support to start-ups, SME IPO.</div> */}
            
        </div>
        </div>
    </div>
  )
}

export default About
import React from 'react'

const Contactus = () => {
  return (
    <div id='contact-us'>
         <div className='d-flex justify-content-center'>
            <p  style={{fontSize:'34px',borderBottom:'3px solid black',paddingTop:'95px'}}>CONTACT US</p>
        </div>
    </div>
  )
}

export default Contactus;
import React from 'react'

const Startup = () => {
  return (
    <div id='start-up' >
        <div className='d-flex justify-content-center'>
            <p  style={{fontSize:'34px',borderBottom:'3px solid black',paddingTop:'95px'}}>START-UP SERVICES</p>
        </div>
        <div className='d-flex flex-row justify-content-center flex-wrap pt-2' >
        <div className='p-3'>
            <h5>Analysis of start-up idea</h5>
            <img
                  alt="..."
                  src={require("../assets/img/startup.jpg")}
                />
           
        </div>
        <div className='p-3'>
            <h5>Technical evaluation of an Idea</h5>
            <img
                  alt="..."
                  src={require("../assets/img/technical.jpg")}
                />
           
        </div>
        <div className='p-3'>
            <h5>Commercial viability analysis</h5>
            <img
                  alt="..."
                  src={require("../assets/img/Commercial-viability.jpg")}
                />
           
        </div>
        <div className='p-3'>
            <h5>Mentoring</h5>
            <img
                  alt="..."
                  src={require("../assets/img/mentoring-1.jpg")}
                />
           
        </div>
        <div className='p-3'>
            <h5>Business Support services</h5>
            <img
                  alt="..."
                  src={require("../assets/img/business-support.jpg")}
                />
           
        </div>
        <div className='p-3'>
            <h5>Marketing strategy</h5>
            <img
                  alt="..."
                  src={require("../assets/img/Marketing-Strategies.jpg")}
                />
           
        </div>
        <div className='p-3'>
            <h5>Arrangement of finance</h5>
            <img
                  alt="..."
                  src={require("../assets/img/Arrangement-of-finance.jpg")}
                />
          
        </div>
        </div>
    </div>
  )
}

export default Startup
import Nav from "react-bootstrap/Nav";

import "./App.css"

import About from "./components/About";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Mediacoverage from "./components/Mediacoverage";
import Services from "./components/Services";
import Startup from "./components/Startup";
import Team from "./components/Team";
import Videoslider from "./components/Videoslider";
import Contactus from "./components/Contactus";


function App() {
  return (
    <div className="App">
      <Header />
     <div class="containerr">
     <img
                  alt="..."
                  src={require("./assets/img/slider.jpg")}
                />
   
  <Nav.Link className="btn" href="#aboutus">CLICK ME</Nav.Link>
</div>
      <About />
      <Services />
      <Startup />
      <Team />
      <Mediacoverage />
      <Gallery />
      <Videoslider />
      <Contactus />
      <Footer />
    </div>
  );
}

export default App;

import { useEffect, useState } from "react"
import React from 'react'
import Card from 'react-bootstrap/Card';


const Mediacoverage = () => {
  
  const articles=[
    {
    id:'1',
    src: require("../assets/img/Pudhari-pg-9-29-11-16-3.jpg")
  
   
  },
    {
    id:'1',
    src: require("../assets/img/Sakal-Times-pg-6-30-11-16.jpg")
    
  },
    {
    id:'1',
    src: require("../assets/img/NavBharat-pg-2-30-11-16.jpg")
   
  },
    {
    id:'1',
    src: require("../assets/img/NavRashtrapg2_23-5-16.jpg")
   
  },
    {
    id:'1',
    src: require("../assets/img/Pudharipg2_23-5-16.jpg")
    
  },
    {
    id:'1',
    src: require("../assets/img/PuneSattaPg4_23-5-2016.jpg")
    
  }
]
        const [items,setitems]= useState([]);
        const [visible,setvisible]=useState(4);
        const [abc,setabc]=useState('flex')

        useEffect(() => {
          setitems(articles)
          
        
        
        }, [])
        const loadingmore=()=>{
          setvisible(visible+2)
        }
        
  return (
    <div id="media">
<div id='aboutus'>
        <div className='d-flex justify-content-center '>
            <p style={{fontSize:'34px',borderBottom:'3px solid black',paddingTop:'95px'}}>Achievement Section - year 23-24</p>
        </div>
       
        <div className='d-flex flex-row flex-wrap justify-content-center'>
            <div className='d-block p-4' style={{width:'300px'}}>
                <h3>Fundraising Rs. 240 cr for Ethanol projects based in AP and WB</h3>
              
            </div>
            <div className='d-block p-4' style={{width:'250px'}}>
                <h3>140 cr for power projects in Western Maharashtra.</h3>
              
            </div>
            <div className='d-block p-4' style={{width:'250px'}}>
                <h3>Permissions from Public OMCs for Ethanol</h3>

        </div>
        <div className='d-block p-4' style={{width:'400px'}}>
                <h3>Acquisition deal of Machine manufacturing company by Chennai based company , deal size Rs 125 cr</h3>

        </div>
        </div>
    </div>
  
    
      
        <div className='d-flex justify-content-center'>
            <p   style={{fontSize:'34px',borderBottom:'3px solid black',paddingTop:'95px'}}>MEDIA COVERAGE</p>
        </div>
        <div className='d-flex flex-row justify-content-center flex-wrap p-5'>
    {items.slice(0,visible).map((item)=>(
              
             
              <Card style={{ width: '18rem'}}>
                <a href="google.com">
              <Card.Img  style={{height:'12rem'}} variant="top" src={item.src} />
              </a>
            </Card>
             
           
            )
               
            )}
            </div>
<div className={`d-${abc} flex-row justify-content-center`}>
<button className='bg-dark rounded text-white' onClick={loadingmore} >View More</button>
</div>



    </div>
  )
}

export default Mediacoverage
import React from 'react'

const Services = () => {
  return (
    <div id='services'>
        <h2 className='d-flex justify-content-center' >
            <p style={{fontSize:'34px',borderBottom:'3px solid black',paddingTop:'95px'}}>
                SERVICES
            </p>
        </h2>
        <div className='d-block w-80 justify-content-center px-5'><p>
        We have a professional relations with various business association namely Architects, Builders, Manufacturers from various segments, who are providing cross business opportunities and hence, such strategic alliances develops business and economical benefits. Our expert marketing team facilitates marketing strategy suitable to any business set up.
            </p></div>
            <div className='d-flex flex-row flex-wrap justify-content-center'>
            <div className='d-flex justify-content-space-between px-5' >
              <div className='p-5'>
                <div className='d-inline-block '>Debt Syndication</div>
                <div>know more</div>
                </div>
                <img className='d-flex' style={{borderRadius:'50%',}} 
                 src={require("../assets/img/bannerimage-150x150.png")}
                  alt="" />
                

            </div>
            <div className='d-flex justify-content-space-between ' >
              
                <img className='d-flex' style={{borderRadius:'50%',}} 
                   src={require("../assets/img/VentureCapital-1-150x150.jpg")}
                   alt="" />
                
                <div className='p-5'>
                <div className='d-inline-block '>Private Equity and Venture Capital</div>
                <div>know more</div>
                </div>
            </div>
            </div>
            <div className='d-flex flex-row flex-wrap justify-content-center '>
            <div className='d-flex justify-content-space-between px-5' >
              <div className='p-5'>
                <div className='d-inline-block '>Business set up Services</div>
                <div>know more</div>
                </div>
                <img className='d-flex' style={{borderRadius:'50%',}} 
                 src={require("../assets/img/My_Startup_Business-150x150.jpg")}
                 alt="" />
                

            </div>
            <div className='d-flex justify-content-space-between w-40' >
              
                <img className='d-flex' style={{borderRadius:'50%',}} 
                  src={require("../assets/img/5-150x150.jpg")}
                 alt="" />
                
                <div className='p-5'>
                <div className='d-inline-block '>Capital Market services</div>
                <div>know more</div>
                </div>
            </div>
            </div>
            <div className='d-flex flex-row flex-wrap justify-content-center '>
            <div className='d-flex justify-content-space-between px-5' >
              <div className='p-5'>
                <div className='d-inline-block '>MARKETING SERVICES</div>
                <div>know more</div>
                </div>
                <img className='d-flex' style={{borderRadius:'50%',}}
                  src={require("../assets/img/FINANCE-150x150.png")}
                 alt="" />
                

            </div>
            <div className='d-flex justify-content-space-between ' >
              
                <img className='d-flex' style={{borderRadius:'50%',}} 
                 src={require("../assets/img/FINANCE-150x150.png")}
                 alt="" />
                
                <div className='p-5'>
                <div className='d-inline-block '>Virtual CFO</div>
                <div>know more</div>
                </div>
            </div>
            </div>
           
    </div>
  )
}

export default Services
import React,{useState,useEffect} from 'react'

import Card from 'react-bootstrap/Card';

const Gallery = () => {
  const articles=[
    {
    id:'1',
    src: require("../assets/img/IMG_2834.jpg")
  
  },
    {
    id:'2',
    src: require("../assets/img/IMG_2842.jpg")
  
  },
    {
    id:'3',
    src: require("../assets/img/IMG_2833.jpg")
   
  },
    {
    id:'4',
    src: require("../assets/img/GAGANDEEP-SINGH.jpg")
   
  },{
    id:'5',
    src: require("../assets/img/Dignitories-on-dias.jpg")
   
  }
]

        const [items,setitems]= useState([]);
        const [visible,setvisible]=useState(4);
        const [abc,setabc]=useState('flex')

        useEffect(() => {
          setitems(articles)
     
        
        
        }, [])
        const loadingmore=()=>{
          setvisible(visible+2)
        }
        
  return (
    <div id='gallery'>
        <div className='d-flex justify-content-center'>
            <p  style={{fontSize:'34px',borderBottom:'3px solid black',paddingTop:'95px'}}>GALLERY</p>
        </div>
       
<div className='d-flex flex-row justify-content-center flex-wrap p-5'>
    {items.slice(0,visible).map((item)=>(
              
             
              <Card style={{ width: '18rem'}}>
              <Card.Img variant="top" src={item.src} />
            </Card>
             
           
            )
               
            )}
            </div>

<div className={`d-${abc} flex-row justify-content-center`}>
<button onClick={loadingmore} className='bg-dark rounded text-white' >View More</button>
</div>


    </div>
  )
}

export default Gallery
import React from 'react'
import Card from 'react-bootstrap/Card';

const Footer = () => {
  return (
    <div>
        <Card>
    
      <Card.Body className='d-flex flex-wrap justify-content-between bg-dark text-white'>
       
        <Card.Text>
        Copyright ©2022 SAN Corporate Advisors All Rights Reserved
        </Card.Text>
        <Card.Text>
        powered by 99IDEAS Infotech Services LLP 
        <img src="https://sanadvisors.in/san/wp-content/themes/accesspress-parallax/images/99IDEAS.png"/>
        </Card.Text>
       
      </Card.Body>
    </Card>

    </div>
  )
}

export default Footer